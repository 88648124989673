<template>
  <div>
    <section class="testimonials-v2 container bg-brwn-radial">
      <div class="content content-centered">
        <flickity ref="flickity" :options="flickityOptions" class="carousel text-xs-center">
          <article class="carousel-cell py-4" v-for="(testimonial, idx) in testimonials" :key="idx">
            <blockquote class="mx-3 font-sm">
              {{testimonial.fields.paragraphs}}
            </blockquote>
            <div class="profile">
              <img :src="testimonial.fields.userAvatar.fields.file.url" class="mt-4" alt="avatar">
              <div class="mb-4 mt-2">
                <small class="font-xs">
                  <h5 class="mt-3 mb-2">{{testimonial.fields.userName}}</h5>
                  <span class="d-block mb-2">
                    {{testimonial.fields.userPosition}}
                  </span>
                </small>
              </div>
            </div>
          </article>
        </flickity>
      </div>
    </section>
  </div>
</template>

<script>
  import { getContent } from '@/javascripts/contentful';
  import Flickity from 'vue-flickity';

  export default {
    name: 'testimonialApp',
    components: { Flickity },
    props: ['testimonials'],

    data() {
      return {
        dataWasDownloadedSuccessfully: false,
        message: "",
        flickityOptions: {
          "fade": true,
          "autoPlay": 15000,
          "prevNextButtons": false,
          "pageDots": true,
          "imagesLoaded": true,
          "pauseAutoPlayOnHover": false
        }
      }
    }
  }
</script>
