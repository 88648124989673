<template>
  <div v-if="dataWasDownloadedSuccessfully">
    <div class="box-wrapper d-grid column-gap-3 row-gap-3 text-left">
      <div v-for="(event, idx) in eventCards" :key="idx" class="box-events-feature">
        <img :src="event.fields.eventImage.fields.file.url" alt="" v-if="event.fields.eventImage">
        <div class="px-3 py-4 px-xl-5">
          <div>
            <small class="d-inline mb-2">{{event.fields.eventDateAndPlace}}</small>
            <h4 class="mb-3">{{event.fields.headline}}</h4>
            <p>{{event.fields.paragraphs}}</p>
          </div>
          <a :href="event.fields.eventButtonLink" class="btn btn-primary-inverted" :title="event.fields.eventButtonTitle" target="_blank">
            {{event.fields.eventButtonLabel}}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getContent } from '@/javascripts/contentful';
  import _ from 'lodash'

  export default {
    name: 'eventApp',
    props: ["events", "count"],
    data() {
      return {
        dataWasDownloadedSuccessfully: false,
        eventCards: [],
      }
    },

    async mounted() {
      const { fields } = await getContent(this.events.sys.id)
      if (fields) {
        this.eventCards = [...fields.eventCards]
        this.dataWasDownloadedSuccessfully = true
      }
      this.eventCards = _.take(_.sortBy(this.eventCards, ['fields.eventDate']), this.count)
    },
  }
</script>
