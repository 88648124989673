<template>
  <section class="locations container bg-wht">
    <div class="content content-centered content-narrow content-roomy trimmed-width-3 d-grid pb-4">
      <div class="group-description text-xs-center text-md-left mb-5 mb-md-0 mt-xl-5">
        <h3 class="h-2 mb-4" data-ctf-location-title></h3>
        <p class="mb-4" data-ctf-location-text></p>
        <a href="#" class="btn btn-primary" data-track="" data-ctf-location-btn-label data-ctf-location-seo-title
          data-ctf-location-link>
        </a>
      </div>
      <div v-if="dataWasDownloadedSuccessfully">
        <div class="map-wrapper text-lg-center">
          <flickity ref="flickityNav" :options="flickityOptions" class="main-carousel">
            <div class="carousel-cell" v-for="standort in standorte" :key="standort.fields.name">
              <img :src="standort.fields.map.fields.file.url" :alt="standort.fields.name">
              <h4 class="m-0 px-2 py-2 text-xs-center">
                {{standort.fields.name}}
              </h4>
            </div>
          </flickity>
        </div><!-- .content -->
      </div>
    </div>
  </section>
</template>
<script>
  import { getContent } from '@/javascripts/contentful';
  import Flickity from 'vue-flickity';
  import _ from 'lodash';

  export default {
    name: 'locationApp',
    components: { Flickity },
    props: ["locations"],
    data() {
      return {
        dataWasDownloadedSuccessfully: false,
        standorte: [],
        flickityOptions: {
          "fade": 1,
          "autoPlay": 1500,
          "pageDots": false,
          "prevNextButtons": false,
          "lazyLoad": true,
          imagesLoaded: true
        }
      }
    },
    async mounted() {
      const { fields } = await getContent(this.locations[0].sys.id)
      if (fields) {
        this.standorte = [...fields.standorte]
        this.dataWasDownloadedSuccessfully = true
      }
      this.standorte = _.sortBy(this.standorte, ['fields.orderNumber'])
    },
  }
</script>
