import '@/javascripts/effects';
import './home.scss';
import 'flickity-fade';

import Vue from 'vue';
import carouselHeroApp from '@/Vue/carouselHeroApp';
import serviceCardApp from '@/Vue/serviceCardApp';
import testimonial from '@/Vue/testimonialApp';
import ourClient from '@/Vue/ourClientApp';
import footerApp from '@/Vue/footerApp';
import events from '@/Vue/eventsApp';
import locationApp from '@/Vue/locationApp';
import sanitizeHTML from 'sanitize-html';

import { getContent } from '@/javascripts/contentful';
import { checkDataStructure } from '@/javascripts/checkDataStructure';

getContent('3IwRAqETLvFJtZT7I5v9ed').then(({ fields }) => {
  // retrieve ctf
  // eslint-disable-next-line no-new
  new Vue({
    el: '#carouselHeroApp',
    data: {
      heroSliderItems: fields.heroSliderItems,
    },
    components: {
      carousel: carouselHeroApp,
    },
  });

  // eslint-disable-next-line no-new
  new Vue({
    el: '#eventsApp',
    data: {
      events: fields.events,
      count: 3,
    },
    components: {
      events,
    },
  });

  // eslint-disable-next-line no-new
  new Vue({
    el: '#locationApp',
    data: {
      locations: fields.locations,
    },
    components: {
      locationApp,
    },
  });

  // eslint-disable-next-line no-new
  new Vue({
    el: '#testimonialApp',
    data: {
      testimonials: fields.testimonials,
    },
    components: {
      testimonial,
    },
  });

  checkDataStructure(fields.callToAction);
  checkDataStructure(fields.services);
  checkDataStructure(fields.ourClients);
  checkDataStructure(fields.events);
  checkDataStructure(fields.locations);
  checkDataStructure(fields.gppGruppe);
  checkDataStructure(fields.footer);
});

Vue.prototype.$sanitize = sanitizeHTML;

// eslint-disable-next-line no-new
new Vue({
  el: '#serviceCard',
  components: {
    'service-card': serviceCardApp,
  },
});

// eslint-disable-next-line no-new
new Vue({
  el: '#ourClientApp',
  components: {
    ourClient,
  },
});

// eslint-disable-next-line no-new
new Vue({
  el: '#footerApp',
  components: {
    footerApp,
  },
});
