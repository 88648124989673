<template>
  <div v-if="dataWasDownloadedSuccessfully">
  <div class="content content-centered content-roomy content-narrow trimmed-width-4 d-grid pt-0">
    <div class="box-wrapper">
      <div class="box-img-feature my-5 my-md-0" v-for="(card, idx) in ourClientCards" :key="idx">
        <img :src="card.clientImage.fields.file.url">
        <h5 class="mb-2">{{ card.clientIndustryName }}</h5>
        <span class="d-inline font-xs">{{ card.clientIndustryParagraphs }}</span>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
  import { getContent } from '@/javascripts/contentful';
  import _ from 'lodash';

  export default {
    name: 'ourClientApp',
    data() {
      return {
        dataWasDownloadedSuccessfully: false,
        ourClientCards: [],
      }
    },

    async mounted() {
      const { fields } = await getContent('3IwRAqETLvFJtZT7I5v9ed')

      _.each(fields.ourClients, async ({ fields: field }) => {
        _.each(field.ourClientsCard, async (o) => {
          const { fields: card } = await getContent(o.sys.id);
          if (field) {
            this.ourClientCards.push(card);
            this.dataWasDownloadedSuccessfully = true
          }
          this.ourClientCards = _.sortBy(this.ourClientCards, ['orderNumber'])
        })
      })
    },
  }
</script>
