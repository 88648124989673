<template>
  <div v-if="dataWasDownloadedSuccessfully">
    <div class="content content-centered content-roomy trimmed-width-3 pt-0 px-0">
      <flickity ref="flickity" :options="flickityOptions" class="services-carousel">
        <div class="carousel-cell" v-for="(content, idx) in contentCards" :key="idx">
          <div class="excerpt">
            <h4>{{ content.fields.ctaTitle }}</h4>
            <p>{{ content.fields.ctaText }}</p>
          </div>
          <a class="btn btn-primary mt-2" :href="content.fields.ctaLink" data-track="" :title="content.fields.ctaSeoTitle">
            {{ content.fields.ctaBtnLabel }}
          </a>
        </div>
      </flickity>
    </div>
  </div>
</template>

<script>
  import { getContent } from '@/javascripts/contentful';
  import Flickity from 'vue-flickity';

  export default {
    name: 'serviceCardApp',
    components: { Flickity },
    props: { fields: null },
    data() {
      return {
        dataWasDownloadedSuccessfully: false,
        contentCards: [],
        flickityOptions: {
          watchCSS: true,
          prevNextButtons: false,
          pageDots: true
        }
      }
    },

    async mounted() {
      const { fields } = await getContent('38Lhl16r0PFdNBJZ0W8nWM')
      if (fields.scContentCards) {
        this.contentCards = [...fields.scContentCards];
        this.dataWasDownloadedSuccessfully = true
      }
    },
  }
</script>
